import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  signal,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalHandlerService } from 'app/shared/components/modal-handler/modal-handler.service';
import { ANIMATION_DURATION, ModalStateEnum, ModalTypesEnum, SIDE_MODAL_ANIMATION } from '../../config/modal.config';

@Component({
  selector: 'prf-side-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: SIDE_MODAL_ANIMATION,
})
export class SideModalComponent implements AfterViewInit {
  public modalState = signal<ModalStateEnum>(ModalStateEnum.HIDDEN);

  @ViewChild('content', { read: ViewContainerRef, static: true }) public content: ViewContainerRef;

  private modalHandlerService = inject(ModalHandlerService);
  private changeDetector = inject(ChangeDetectorRef);

  public ngAfterViewInit(): void {
    this.modalHandlerService.getModalTemplate(ModalTypesEnum.SIDE).subscribe(template => {
      if (template === undefined) return;
      if (template) {
        this.content.createEmbeddedView(template);
        this.modalState.set(ModalStateEnum.VISIBLE);
      } else {
        setTimeout(() => {
          this.content.clear();
          this.modalState.set(ModalStateEnum.HIDDEN);
          this.changeDetector.detectChanges();
        }, ANIMATION_DURATION);
      }
    });
  }

  public closeModal(): void {
    this.modalHandlerService.destroyModal(ModalTypesEnum.SIDE);
  }

  protected readonly ANIMATION_DURATION = ANIMATION_DURATION;
}
