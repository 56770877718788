import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { NgIf } from '@angular/common';

import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { OVERLAY_DICTIONARY } from 'assets/app-dictionaries/ru/overlay.dictionary';

@Component({
  selector: 'prf-overlay-busy',
  templateUrl: './overlay-busy.component.html',
  styleUrls: ['./overlay-busy.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class OverlayBusyComponent implements OnInit, OnDestroy {
  public showLoader = signal<boolean>(false);
  public text = signal<string>('');

  private overlayBusyService = inject(OverlayBusyService);

  public ngOnInit(): void {
    this.overlayBusyService.register({
      show: (text: string) => {
        this.showLoader.set(true);
        if (text) {
          this.text.set(text);
        }
      },
      hide: () => this.showLoader.set(false),
    });
  }

  public ngOnDestroy(): void {
    this.overlayBusyService.unregister();
  }
}
