import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { OverlayBusyComponent } from 'app/shared/components/overlay-busy/overlay-busy.component';
import { ModalComponent } from 'app/shared/components/modal/modal.component';
import { ThemeControllerService } from 'app/shared/services/theme-controller.service';
import { debounce } from 'app/shared/utils/helpers';

@Component({
  selector: 'prf-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, OverlayBusyComponent, ModalComponent],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private fn = debounce(() => {
    document.documentElement.style.setProperty('--prf-app-height', `${window.innerHeight}px`);
  }, 200);

  constructor(
    private overlayBusyService: OverlayBusyService,
    private themeControllerService: ThemeControllerService,
  ) {}

  public ngOnInit() {
    this.fn();
    window.addEventListener('resize', this.fn);
  }

  public ngAfterViewInit(): void {
    this.overlayBusyService.show();
  }

  public ngOnDestroy() {
    window.removeEventListener('resize', this.fn);
  }
}
