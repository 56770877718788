import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CenterModalComponent } from './components/center-modal/center-modal.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { BottomModalComponent } from './components/bottom-modal/bottom-modal.component';

@Component({
  selector: 'prf-modal',
  standalone: true,
  imports: [CommonModule, CenterModalComponent, SideModalComponent, BottomModalComponent],
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {}
