<div [@modalVisibility]="modalState()" class="modals-wrapper">
  <div class="modal-background" (click)="closeModal()"></div>
  <div [@modalOpacity]="modalState()" class="center-modal" [style.transition-duration]="ANIMATION_DURATION + 'ms'">
    <ng-container #content />
    <button class="close-button" (click)="closeModal()" type="button">
      <svg class="close-icon" xmlns="http://www.w3.org/2000/svg">
        <use [attr.href]="'./profilum-components/icons/all-icons.svg#close'"></use>
      </svg>
    </button>
  </div>
</div>
